@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sen&display=swap);


body {
    font-family: Montserrat, Sen, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
}

.tile {
    padding: spacing(0.5);
    overflow: 'hidden';
    display: grid;
    align-items: start;
}

.Collapsible {
    background-color: rgb(255, 255, 255);
    display: flow;
}


.Collapsible__contentInner {
    padding: 10px;
    border-top: 0;
}

.Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 1rem;
    line-height: 1.5;
}

.Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}

.Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: rgb(242, 122, 84);
    position: relative;
    padding: 10px;
}

.Collapsible__trigger:after {
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 150ms;
}

.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.CollapsibleOpenFirst {
    font-size: 1rem;
    font-family: Montserrat, Sen, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    font-style: oblique;
    color: black !important;
}

.CollapsibleOpenFirst--open {
    font-size: 1rem;
    font-family: Montserrat, Sen, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    line-height: 1.5;
    font-style: oblique;
    color: black !important;
}

.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 1rem;
    background-color: #CBB700;
    color: black;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

.input-search {
    display: block;
    width: 100%;
    padding: 8px 4px;
    font-size: 1rem;
    font-family: Montserrat, Sen, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px;
    outline: none;
    border: 1px solid #F27A54;

    &::-webkit-input-placeholder {
        color: #999999;
        font-weight: 400;
    }
}

.site-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    max-height: 400px;
}

.site-list:hover {
    overflow-y: scroll;
}

.site-item {
    margin-bottom: 8px;
    border: 1px solid white;
    color: #131815;
    font-weight: 400;
    padding: 8px 8px;
}
